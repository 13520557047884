export interface MockedVersionProps {
  version: string
  uploadDate: Date
  user: string
  comment: string
}
export interface MockedVersionsProps {
  [key: string]: {
    [key: string]: MockedVersionProps[]
  }
}

export interface RegionProps {
  HOLIDAY_REGION: string
}
export interface IUsePublic {
  yearOptions: number[]
}

export enum FileExtEnum {
  CSV = 'csv',
  XLSX = 'xlsx'
}

/* istanbul ignore file */
import React, { createContext, useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { useSnackbar } from 'notistack'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { IUserContext, IUserInfo } from 'types'

export const UserContext = createContext<IUserContext>({})

export const UserContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()

  const [user, setUser] = useState<null | undefined | IUserInfo>(undefined) // null means loading
  const [isUserLoading, setIsUserLoading] = useState(true)

  useEffect(() => {
    void getUser()
  }, [])

  useEffect(() => {
    if (user === null) void Auth.federatedSignIn()
  }, [user])

  async function getUser (): Promise<void> {
    setIsUserLoading(true)
    let errorMessage = ''
    try {
      const returnedUser = await Auth.currentUserInfo()
      const newUser = returnedUser ?? null
      setUser(newUser)
      if (newUser === null) {
        errorMessage = 'You are not signed in'
      }
    } catch (error) {
      console.log({ error })
      errorMessage = 'Sign-in failed'
    }
    if (errorMessage.length > 0) {
      enqueueSnackbar(errorMessage, {
        action: key =>
          <IconButton aria-label='delete' onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>,
        variant: 'error',
        persist: true
      })
    }
    setIsUserLoading(false)
  }

  return (
    <UserContext.Provider value={{ user, setUser, isUserLoading }}>
      {children}
    </UserContext.Provider>
  )
}

/* istanbul ignore file */
import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material'
import { Amplify, API } from 'aws-amplify'
import { SnackbarProvider } from 'notistack'

import { UserContextProvider } from 'context'
import awsmobile from 'aws-exports'
import { theme } from './theme'
import App from './App'

awsmobile.oauth.redirectSignIn = `${window.location.origin}/`
awsmobile.oauth.redirectSignOut = `${window.location.origin}/`

Amplify.configure(awsmobile)
API.configure(awsmobile)

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={5}>
        <UserContextProvider>
          <CssBaseline />
          <App />
        </UserContextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

import { CellTypes, IErrorObj, IGetFirstRowErrorProps } from 'types'

import { cellMustHaveType } from './generic-cell-validation'
import { cellRangeValidation } from './generic-cellrange-validation'
import { getNumberOfColumnsError, getSheetCountError } from './generic-file-validation'
import { getColLetterFromColNumber, getColNumberFromColLetter } from './xlsx-utils'

export const getFirstRowErrors = ({ cleanSheet, expectedNumberOfColumns, lastCleanSheetColumnLetter, Sheets }: IGetFirstRowErrorProps): IErrorObj[] => {
  const cellTypeErrors: IErrorObj[] = []
  // make sure column titles exist
  for (let i = 0; i < expectedNumberOfColumns; i++) {
    const columnLetter = getColLetterFromColNumber(i + 1)
    const cellKey = `${columnLetter}1`
    const cellTypeError = cellMustHaveType(cleanSheet[cellKey], cellKey, CellTypes.String)
    if (cellTypeError !== undefined) cellTypeErrors.push(cellTypeError)
    if (columnLetter === lastCleanSheetColumnLetter) break
  }

  // indicate which cells exist that shouldn't
  let shouldNotExistErrors: IErrorObj[] = []
  const actualLastColNumber = getColNumberFromColLetter(lastCleanSheetColumnLetter)
  if (actualLastColNumber > expectedNumberOfColumns) {
    shouldNotExistErrors = cellRangeValidation(`${getColLetterFromColNumber(expectedNumberOfColumns + 1)}1:${lastCleanSheetColumnLetter}1`, cleanSheet, { shouldExist: false })
  }

  const columnLimitErrors = getNumberOfColumnsError({
    expectedNumberOfColumns,
    lastCleanSheetColumnLetter
  })
  const sheetCountError = getSheetCountError(Sheets, lastCleanSheetColumnLetter)
  const expectedLastColumnLetter = getColLetterFromColNumber(expectedNumberOfColumns)
  const mustExistErrors = cellRangeValidation(`A1:${expectedLastColumnLetter}1`, cleanSheet)

  const errors = ([] as IErrorObj[]).concat(cellTypeErrors, columnLimitErrors, sheetCountError, shouldNotExistErrors, mustExistErrors)

  return errors
}

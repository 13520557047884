import { WorkSheet, read, writeFile, utils as xlsxUtils, WorkBook } from 'xlsx'
import { isEmpty } from 'lodash'

import { ICellKeysByColumn, ICommonValidationVars, IErrorsByRow, IErrorObj, IReadFileAndGetVarsParams, IAddErrorMessagesToSheetByRow, IReplaceEmptyCellConfig, CellTypes, IisFileValidParams, IisFileValidObj } from 'types'
import { alphabet, errorColTitle, fileReaderErrorMessage, titleErrorPrefix, validationFailedErrorMessage, xlsxReadErrorMessage } from 'common'
import { getNumberFromString, removeNumbersFromString } from './string'
import { getCellsFromCellRange } from './generic-cellrange-validation'

//    CREATE
export const readFileAndGetVars = async ({ expectedNumberOfColumns, file }: IReadFileAndGetVarsParams): Promise<ICommonValidationVars> => {
  let variables: any = { snackbarErrors: [] }
  void await new Promise((resolve) => {
    try {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)
      fileReader.onload = (e) => {
        const bufferArray = e?.target?.result
        try {
          const workbook = read(bufferArray, {
            cellDates: true,
            cellStyles: true,
            sheetStubs: true,
            type: 'buffer'
          })
          const { Sheets } = workbook
          const dirtySheet = Sheets[Object.keys(Sheets)[0]]
          const dirtySheetCellRange = dirtySheet?.['!ref'] ?? ''
          const lastDirtySheetColumnLetter = getLastColumnLetterFromCellRange(dirtySheetCellRange)
          const cleanSheet = replaceSpecialCharactersInSheet(removeErrorsFromSheet(dirtySheet, expectedNumberOfColumns, lastDirtySheetColumnLetter))
          const cleanSheetCellRange = cleanSheet?.['!ref'] ?? ''
          const scrubbedSheetKeys = sortCellKeys(Object.keys(cleanSheet))
          variables = {
            cleanSheet,
            keysByColumn: getCellKeysByColumn(scrubbedSheetKeys),
            lastCleanSheetColumnLetter: getLastColumnLetterFromCellRange(cleanSheetCellRange),
            Sheets,
            workbook
          }
          resolve(variables)
        } catch (error: unknown) {
          console.log(error)
          variables.snackbarErrors.push(xlsxReadErrorMessage)
          resolve(variables)
        }
      }
    } catch (error: unknown) {
      console.log(error)
      variables.snackbarErrors.push(fileReaderErrorMessage)
      resolve(variables)
    }
  })
  return { ...variables }
}

export const formatErrorMessage = (message: string, cellKey: string): IErrorObj => {
  const cellText = cellKey !== '' ? `[Cell:${cellKey}] ` : ''
  return { cellKey, msg: `${cellText}${message}` }
}

export const createWorkbookFromSheet = (sheet: WorkSheet, workbook: WorkBook, sheetName: string, workbookName: string): void => {
  if (process.env.NODE_ENV !== 'test') {
    const newWorkBook = workbook // create new workbook
    try {
      xlsxUtils.book_append_sheet(workbook, sheet, sheetName) // add sheet to workbook
    } catch (error) {
      console.log({ error })
      xlsxUtils.book_append_sheet(workbook, sheet, `${sheetName}-1`) // add sheet to workbook
    }
    writeFile(newWorkBook, workbookName, { cellStyles: true }) // download workbook
  }
}

export const updateSheetRef = (sheet: WorkSheet): WorkSheet => { // get accurate sheet ref range
  const jsonSheet = xlsxUtils.sheet_to_json(sheet, { blankrows: true, defval: '' })
  const newRef = xlsxUtils.json_to_sheet(jsonSheet)?.['!ref']
  sheet['!ref'] = newRef
  return { ...sheet, '!ref': newRef }
}

export const isValidOrCreateWorkbook = ({ cleanSheet, errorsByRowNotTitles, fileName, firstRowErrors, sheetName, workbook }: IisFileValidParams): IisFileValidObj => {
  const additionalSnackbarErrors = []
  if (!isEmpty(errorsByRowNotTitles) || !isEmpty(firstRowErrors)) {
    const newSheet: WorkSheet = addErrorMessagesToSheetByRow({ cleanSheet, errorsByRowNotTitles, firstRowErrors })
    additionalSnackbarErrors.push(validationFailedErrorMessage)
    createWorkbookFromSheet(newSheet, workbook, sheetName, fileName)
  }
  return { additionalSnackbarErrors }
}

//    RETRIEVE/GET
export const getTitleArrayFromSheet = (sheet: WorkSheet): string[] => {
  const titleCellNumbers: string[] = Object.keys(sheet).filter((cellKey: string) => getNumberFromString(cellKey) === 1)
  const titleStringsArr: string [] = titleCellNumbers.map(cellKey => sheet[cellKey].v)
  return titleStringsArr
}

export const getColNumberFromColLetter = (columnLetter: string): number => { // column number is NOT column index, index + 1
  const base = alphabet.join('')
  let i
  let j
  let columnNumber = 0

  for (i = 0, j = columnLetter.length - 1; i < columnLetter.length; i += 1, j -= 1) {
    columnNumber += Math.pow(base.length, j) * (base.indexOf(columnLetter[i]) + 1)
  }

  return columnNumber
}

export const getColLetterFromColNumber = (columnNumber: number): string => { // column number is NOT column index, index + 1
  let columnLetter = ''
  let t

  while (columnNumber > 0) {
    t = (columnNumber - 1) % 26
    columnLetter = String.fromCharCode(65 + t) + columnLetter
    columnNumber = (columnNumber - t) / 26 | 0
  }

  return columnLetter
}

export const getLastColumnLetterFromCellRange = (cellRange: string): string => removeNumbersFromString(cellRange?.split(':')?.[1])
export const getFirstRowNumberFromCellRange = (cellRange: string): number => getNumberFromString(cellRange.split(':')[0])
export const getLastRowNumberFromCellRange = (cellRange: string): number => getNumberFromString(cellRange.split(':')[1])

export const getCellKeysByColumn = (scrubbedSheetKeys: string[]): ICellKeysByColumn => {
  const cellKeysByColumn: {[cell: string]: string[]} = {}
  const uniqueColumnLetters = new Set(scrubbedSheetKeys.map((cellKey: string) => removeNumbersFromString(cellKey)))
  uniqueColumnLetters.forEach((uniqueColLetter: string) => {
    const columnKeys = scrubbedSheetKeys.filter(cellKey => removeNumbersFromString(cellKey) === uniqueColLetter)
    cellKeysByColumn[uniqueColLetter] = columnKeys
  })

  return cellKeysByColumn
}

export const getErrorsByRow = (errors: IErrorObj[]): IErrorsByRow => {
  const errorsByRow: IErrorsByRow = {}
  errors.forEach((errorObj: IErrorObj) => {
    const rowNumber = getNumberFromString(errorObj?.cellKey)
    if (errorsByRow?.[rowNumber] === undefined) errorsByRow[rowNumber] = []
    errorsByRow[rowNumber].push(errorObj.msg)
  })
  return errorsByRow
}

export const getLastRowNumberFromColumn = (cellKeys: string[]): number => {
  const sortedCellKeys = sortCellKeys(cellKeys)
  const lastCellKey: string = sortedCellKeys[sortedCellKeys.length - 1]
  const lastRowNumber = getNumberFromString(lastCellKey)
  return lastRowNumber
}

export const getRowUniquenessErrors = (cleanSheet: WorkSheet, uniqueColumnLetters: string[]): IErrorObj[] => {
  const errors: IErrorObj[] = []
  const numberOfRows = getNumberFromString(cleanSheet?.['!ref']?.split(':')?.[1] ?? '0')
  const rowsArr: any[] = []

  // group each row
  for (let currentRowIndex = 1; currentRowIndex < numberOfRows; currentRowIndex++) { // skip header rows
    const rowArr: string[] = uniqueColumnLetters.map((columnLetter: string): string => cleanSheet[`${columnLetter.toUpperCase()}${currentRowIndex + 1}`]?.v?.toString())
    rowsArr.push(rowArr.join(''))
  }

  const lastUniqueColLetter = uniqueColumnLetters[uniqueColumnLetters.length - 1]

  // find duplicates
  for (let currentRowIndex = 0; currentRowIndex < rowsArr.length; currentRowIndex++) {
    for (let referenceRowIndex = 0; referenceRowIndex < rowsArr.length; referenceRowIndex++) {
      const currentRow = rowsArr[currentRowIndex]
      const referenceRow = rowsArr[referenceRowIndex]
      if (currentRowIndex !== referenceRowIndex && currentRow === referenceRow) {
        const errorMessage = `Row Columns ${uniqueColumnLetters.join(',')} must be unique, but it is a duplicate value of ${lastUniqueColLetter}${currentRowIndex + 1}`
        errors.push(formatErrorMessage(errorMessage, `${lastUniqueColLetter}${referenceRowIndex + 2}`)) // +2 because title row was skipped
      }
    }
  }
  return errors
}

//    REMOVE/DELETE
export const removeErrorsFromSheet = (sheet: WorkSheet, expectedNumberOfColumns: number, lastColumnLetter: string): WorkSheet => {
  let newSheet = sheet
  const expectedErrorColLetter = getColLetterFromColNumber(expectedNumberOfColumns + 1)
  const expectedErrorCell = sheet[`${expectedErrorColLetter}1`]
  if (expectedErrorCell?.v === errorColTitle) {
    const lastTitleCell = sheet[`${lastColumnLetter}1`]
    const numberOfColumns = getColNumberFromColLetter(lastColumnLetter)
    const secondToLastColLetter = getColLetterFromColNumber(numberOfColumns - 1)
    const isErrorColumn2ndToLast: boolean = sheet[`${secondToLastColLetter}1`]?.v === errorColTitle
    const isLastColumnTitleErrors: boolean = lastTitleCell?.v?.indexOf(titleErrorPrefix) === 0
    if (expectedErrorColLetter === lastColumnLetter) { // if error col is last col delete error column
      newSheet = removeColumnFromSheet(newSheet, lastColumnLetter)
    } else if (isErrorColumn2ndToLast && isLastColumnTitleErrors) {
      newSheet = removeColumnFromSheet(newSheet, secondToLastColLetter)
      newSheet = removeColumnFromSheet(newSheet, lastColumnLetter)
    }
  }
  return updateSheetRef(newSheet)
}

export const removeColumnFromSheet = (sheet: WorkSheet, deleteColumnLetter: string): WorkSheet => {
  const newSheet: WorkSheet = {}
  Object.keys(sheet).filter((cellKey: string) => cellKey.replace(/[0-9]/g, '') !== deleteColumnLetter).forEach((cellKey: string) => {
    newSheet[cellKey] = sheet[cellKey]
  })

  return newSheet
}

//    ADD or REPLACE
export const addErrorMessagesToSheetByRow = ({ cleanSheet, errorsByRowNotTitles, firstRowErrors }: IAddErrorMessagesToSheetByRow): WorkSheet => {
  let newWorkSheet: any = cleanSheet
  if (!isEmpty(errorsByRowNotTitles)) {
    const currentLastColumnLetter = removeNumbersFromString(cleanSheet?.['!ref']?.split(':')?.[1] ?? '')
    const errorColumnNumber = getColNumberFromColLetter(currentLastColumnLetter) + 1
    const errorColumnLetter = getColLetterFromColNumber(errorColumnNumber)
    xlsxUtils.sheet_add_aoa(newWorkSheet, [[errorColTitle]], { origin: `${errorColumnLetter}1` })
    Object.keys(errorsByRowNotTitles).forEach((rowString: string) => {
    // group all row error messages by row into one string
      const rowNumber = parseInt(rowString)
      const errorRow: string[] = errorsByRowNotTitles[rowString]
      let errorsString = ''
      errorRow.forEach((errorMessage: string) => {
        const errorString = `${errorMessage};   \n`
        errorsString = errorsString.concat(errorString)
      })
      xlsxUtils.sheet_add_aoa(newWorkSheet, [[errorsString]], { origin: `${errorColumnLetter}${rowNumber}` })
    })
  }
  if (!isEmpty(firstRowErrors)) {
    let titleErrorString = `${titleErrorPrefix}`
    firstRowErrors.forEach(errorObj => { titleErrorString = titleErrorString.concat(`${errorObj?.msg};   \n`) })
    const titles = getTitleArrayFromSheet(newWorkSheet)
    newWorkSheet = xlsxUtils.sheet_add_aoa(newWorkSheet, [[...titles, titleErrorString]], { origin: 'A1' })
  }

  return updateSheetRef(newWorkSheet)
}

export const replaceEmptyCellsInRange = (cleanSheet: WorkSheet, replaceEmptyCellConfig: IReplaceEmptyCellConfig): WorkSheet => {
  const missingCells = getCellsFromCellRange(replaceEmptyCellConfig.cellRange, cleanSheet)

  const newSheet = { ...cleanSheet }
  missingCells.forEach(cellKey => {
    newSheet[cellKey] = replaceEmptyCellConfig.replaceWith
  })
  return newSheet
}

export const replaceSpecialCharactersInSheet = (cleanSheet: WorkSheet): WorkSheet => {
  const newSheet = { ...cleanSheet }
  const firstRowNumber = 1
  const lastRowNumber = getLastRowNumberFromCellRange(cleanSheet['!ref'] ?? '')

  const lastColumnLetter = getLastColumnLetterFromCellRange(cleanSheet['!ref'] ?? 'A1:A2')
  const firstColumnIndex = 0
  const lastColumnNumber = getColNumberFromColLetter(lastColumnLetter)

  // loop through columns
  for (let i = firstColumnIndex; i < lastColumnNumber; i++) {
    //  loop through rows
    for (let j = firstRowNumber - 1; j < lastRowNumber; j++) {
      const currentColLetter = getColLetterFromColNumber(i + 1)
      const currentCellKey = `${currentColLetter}${j + 1}`

      if (newSheet?.[currentCellKey]?.t === CellTypes.String) {
        const newString = newSheet[currentCellKey].v?.replace(new RegExp(String.fromCharCode(160), 'g'), ' ') ?? ''
        const newCell = {
          ...newSheet[currentCellKey],
          h: newString,
          r: `<t>${String(newString)}</t>`,
          v: newString
        }

        newSheet[currentCellKey] = newCell
      }
    }
  }

  return newSheet
}

export const removeNonAlphaNumberCharsFromTitleRow = (cleanSheet: WorkSheet): WorkSheet => {
  const newSheet = { ...cleanSheet }
  // TODO: below line is wrong.  replace getColNumberFromColLetter with a function that shows the row number.
  // const lastRowNumber = getColNumberFromColLetter(getLastColumnLetterFromCellRange(cleanSheet['!ref'] ?? 'A1:A2'))

  // for (let i = 0; i < lastRowNumber; i++) {
  //   const columnLetter = getColLetterFromColNumber(i + 1)
  //   const cellKey = `${columnLetter}1`
  //   if (cleanSheet[cellKey]?.t === CellTypes.String) {
  //     const nonAlphaNumericChars = cleanSheet[cellKey].v?.match(/[^a-z0-9]/gi)
  //     if (nonAlphaNumericChars?.length !== undefined) {
  //       const newString = cleanSheet[cellKey].v?.replace(/[^a-z0-9]/gi, '_')
  //       newSheet[cellKey] = {
  //         ...newSheet[cellKey],
  //         h: newString,
  //         r: `<t>${String(newString)}</t>`,
  //         v: newString,
  //         w: newString
  //       }
  //     }
  //   }
  // }

  return newSheet
}

//    SORT
export const sortCellKeys = (cellKeys: string[]): string[] => {
  const sortedKeys = cellKeys.filter(sheetKey => !sheetKey.includes('!')).sort((aCellKey: string, bCellKey: string) => {
    const aColLetter = removeNumbersFromString(aCellKey)
    const bColLetter = removeNumbersFromString(bCellKey)
    const aRowNumber = getNumberFromString(aCellKey)
    const bRowNumber = getNumberFromString(bCellKey)
    // primary sort
    if (aColLetter < bColLetter) return -1
    if (bColLetter < aColLetter) return 1
    // secondary sort
    if (aRowNumber < bRowNumber) return -1
    if (bRowNumber < aRowNumber) return 1
    return 0
  })
  return sortedKeys
}

import Button from '@mui/material/Button'
import styles from './UploadButton.style'

interface UploadButtonProps{
  children?: React.ReactNode
  isDisabled?: boolean
  icon?: React.ReactNode
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  style?: React.CSSProperties | undefined
  variant?: 'contained' | 'text' | 'outlined'
}

const UploadButton: React.FC<UploadButtonProps> = ({ isDisabled, name, onChange, icon, variant, style, children }) => {
  return (
    <label htmlFor='contained-button-file' data-testid='upload-label'>
      <input style={styles.uploadInput} data-testid='upload-input' id='contained-button-file' disabled={isDisabled === true} type='file' onChange={onChange} accept='.xlsx' />
      <Button data-testid='upload-button' disabled={isDisabled === true} variant={variant} component='span' sx={style}>{icon}{name}{children}</Button>
    </label>
  )
}

export default UploadButton

export default {
  text: {
    fontWeight: 300,
    fontSize: '14px'
  },
  heading: {
    color: '#9E9E9E',
    marginRight: '5px'
  },
  button: {
    fontSize: '20px',
    fontWeight: 400,
    marginTop: '15px'
  },
  card: {
    borderRadius: '8px',
    border: '1px solid #E5E5E5',
    marginTop: '1.6em'
  }
}

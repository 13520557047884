import { API } from 'aws-amplify'

import { IUserInfo } from 'types'
import { formatUserName } from 'utils'

// export const getCurrentVersion = async (partitionKey: string): Promise<number> => {
//   return await API.get('VersionsAPI', `/versions/${partitionKey}`, {}).then((res) => res.length)
// }

export const postMetadata = async (partitionKey: string, version: number, fileName: string, region: string, year: string, user: IUserInfo | null | undefined, comment: string, versionsApiPath: string): Promise<void> => {
  const data = {
    body: {
      country_year: `${partitionKey}`,
      version: version,
      user: formatUserName(user?.attributes?.email ?? 'undefined'),
      comment: comment,
      datetime: new Date().toISOString(),
      filename: fileName
    }
  }
  await API.post('datasucker', versionsApiPath, data)
}

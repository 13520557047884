import { IActAdjConvertedRow } from 'types'

export const convertActAdjToCSV = (json: any[]): any => {
  const convertedRows: IActAdjConvertedRow[] = []
  const months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  json.forEach((data) => {
    months.forEach((monthName: string, monthIndex) => {
      const dateString = `${monthIndex + 1}/01/${data.Year as number}`
      convertedRows.push({
        Account: data.Account,
        Adjustment: data[monthName],
        'Adjustment note': data['Adjustment note'],
        Date: dateString,
        Location: data.Location,
        Project: data.Project,
        Units: data.Units
      })
    })
  })

  return convertedRows
}

import { CellObject } from 'xlsx'

import { isEmpty, size } from 'lodash'
import { IErrorObj, CellTypesNames, CellTypes } from 'types'
import { cellMustHaveLengthErrorMessage, emptyCellErrorMessage } from 'common'

import { formatErrorMessage } from './xlsx-utils'

// variable naming convention "cellCannot...()" OR "cellMust...()"

export const cellCannotBeEmpty = (cell: CellObject, cellKey: string): IErrorObj | undefined => {
  if (isEmpty(cell) || isEmpty(cell?.v)) return formatErrorMessage(emptyCellErrorMessage, cellKey)
}

export const cellMustHaveType = (cell: CellObject, cellKey: string, cellType: string): IErrorObj | undefined => {
  if (cell?.t !== cellType) {
    let errorMessage = `Cell must have cell type '${CellTypesNames[cellType as keyof typeof CellTypesNames]}'`
    if (cellType === CellTypes.Date) {
      if (cell.t === CellTypes.String && typeof cell?.v === 'string' && (cell?.v.charAt(0) === ' ' || (cell?.v !== '' && cell?.v.split('').includes(' ')))) {
        errorMessage = 'Date field cannot have any whitespace'
      }
      if (cell?.t === CellTypes.Stub) {
        errorMessage = errorMessage.concat(". If you don't want this cell validated, delete it.")
      }
    }
    return formatErrorMessage(errorMessage, cellKey)
  }
}

export const cellMustHaveLength = (cellValue: string, cellKey: string, mustHaveLength: number): IErrorObj | undefined => {
  if (size(cellValue) !== mustHaveLength) {
    return formatErrorMessage(cellMustHaveLengthErrorMessage(mustHaveLength), cellKey)
  }
}

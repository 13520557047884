import { WorkSheet } from 'xlsx'

import { CellTypes, IErrorObj, IGetCellsFromCellRangeOptions } from 'types'
import { cellShouldNotExistMessage, emptyCellErrorMessage } from 'common'

import {
  getLastColumnLetterFromCellRange, formatErrorMessage, getColNumberFromColLetter,
  getLastRowNumberFromCellRange, getFirstRowNumberFromCellRange, getColLetterFromColNumber
} from './xlsx-utils'
import { removeNumbersFromString } from './string'

export const getCellsFromCellRange = (cellRange: string, cleanSheet: WorkSheet, options: IGetCellsFromCellRangeOptions = { shouldExist: true }): string[] => { // make sure all cells exist inside cell range
  const cells: string[] = []

  const firstRowNumber = getFirstRowNumberFromCellRange(cellRange)
  const lastRowNumber = getLastRowNumberFromCellRange(cellRange)

  const firstColumnIndex = getColNumberFromColLetter(removeNumbersFromString(cellRange.split(':')[0])) - 1
  const lastColumnLetter = getLastColumnLetterFromCellRange(cellRange)
  const lastColumnNumber = getColNumberFromColLetter(lastColumnLetter)

  // loop through columns
  for (let i = firstColumnIndex; i < lastColumnNumber; i++) {
    //  loop through rows
    for (let j = firstRowNumber - 1; j < lastRowNumber; j++) {
      const currentColLetter = getColLetterFromColNumber(i + 1)
      const currentCellKey = `${currentColLetter}${j + 1}`
      if (options.shouldExist) {
        if (cleanSheet[currentCellKey] === undefined || cleanSheet[currentCellKey]?.v === undefined) {
          cells.push(currentCellKey)
        } else if (cleanSheet[currentCellKey]?.t === CellTypes.String && cleanSheet[currentCellKey]?.v.trim() === '') {
          cells.push(currentCellKey)
        }
      } else if (!options.shouldExist) {
        if (cleanSheet[currentCellKey] !== undefined) {
          cells.push(currentCellKey)
        }
      }
    }
  }

  return cells
}

export const cellRangeValidation = (cellRange: string, cleanSheet: WorkSheet, options: IGetCellsFromCellRangeOptions = { shouldExist: true }): IErrorObj[] => { // make sure all cells exist inside cell range
  const errors: IErrorObj[] = []

  const cells = getCellsFromCellRange(cellRange, cleanSheet, options)
  cells.forEach(cellKey => {
    errors.push(formatErrorMessage(options.shouldExist ? emptyCellErrorMessage : cellShouldNotExistMessage, cellKey))
  })

  return errors
}

import { isEmpty } from 'lodash'

import { CellTypes, IErrorObj, IReplaceEmptyCellConfig, IValidationPromise } from 'types'

import { getColumnErrors } from './generic-column-validation'
import { getFileExtensionErrors } from './generic-file-validation'
import { getFirstRowErrors } from './generic-row-validation'
import {
  getErrorsByRow, getLastRowNumberFromCellRange, isValidOrCreateWorkbook, readFileAndGetVars, replaceEmptyCellsInRange
} from './xlsx-utils'

const columnsConfigArr = [
  { cellType: CellTypes.String },
  { cellType: CellTypes.String },
  { cellType: CellTypes.String },
  { cellType: CellTypes.String },
  { cellType: CellTypes.String },
  { cellType: CellTypes.String },
  { cellType: CellTypes.Number },
  { cellType: CellTypes.Number },
  { cellType: CellTypes.Number },
  { cellType: CellTypes.Number }
]

export const validateBillingDataXLSXFile = async (file: File): Promise<IValidationPromise> => {
  const snackbarErrorMessages: string[] = [] // default to none
  let fileToReplaceForConversion
  await new Promise((resolve): void => {
    void (async () => {
      // validate file ext
      const fileExtensionErrors = getFileExtensionErrors(file)
      if (fileExtensionErrors !== undefined) {
        snackbarErrorMessages.push(...fileExtensionErrors)
        return resolve('')
      }

      const expectedNumberOfColumns = 10

      const { cleanSheet, keysByColumn, lastCleanSheetColumnLetter, snackbarErrors, Sheets, workbook } = await readFileAndGetVars({ expectedNumberOfColumns, file })
      if (!isEmpty(snackbarErrors)) {
        snackbarErrorMessages.push(...snackbarErrors)
        return resolve('')
      }

      // ERROR MESSAGES
      //     Title row errors
      const firstRowErrors = getFirstRowErrors({ cleanSheet, expectedNumberOfColumns, lastCleanSheetColumnLetter, Sheets })

      // replace empty cells in cols G & H
      const replaceEmptyCellConfig: IReplaceEmptyCellConfig = {
        cellRange: `G2:H${getLastRowNumberFromCellRange(cleanSheet['!ref'] ?? '')}`,
        includeEmptyStrings: true,
        replaceWith: {
          s: { patternType: 'none' },
          t: 'n',
          v: 0.0,
          w: '0.0',
          z: '#,##0.0'
        }
      }

      // replace empty cells in cols I & J
      const replaceEmptyCellConfig2: IReplaceEmptyCellConfig = {
        cellRange: `I2:J${getLastRowNumberFromCellRange(cleanSheet['!ref'] ?? '')}`,
        includeEmptyStrings: true,
        replaceWith: {
          s: { patternType: 'none' },
          t: 'n',
          v: 0.00,
          w: '$ 0',
          z: '\\$\\ #,##0'
        }
      }

      let sheetWithReplacedCells = replaceEmptyCellsInRange(cleanSheet, replaceEmptyCellConfig)
      sheetWithReplacedCells = replaceEmptyCellsInRange(sheetWithReplacedCells, replaceEmptyCellConfig2)

      const columnErrorsArr: IErrorObj[][] = columnsConfigArr.map((colConfig, index) => getColumnErrors({
        colIndex: index,
        colType: colConfig.cellType,
        cleanSheet: sheetWithReplacedCells,
        keysByColumn
      }))

      const errorsByRowNotTitles = getErrorsByRow(([] as IErrorObj[]).concat(...columnErrorsArr))

      const { additionalSnackbarErrors } = isValidOrCreateWorkbook({
        cleanSheet: sheetWithReplacedCells,
        errorsByRowNotTitles,
        fileName: 'BillingData.xlsx',
        firstRowErrors,
        sheetName: 'Billing Data',
        workbook
      })

      fileToReplaceForConversion = sheetWithReplacedCells

      snackbarErrorMessages.push(...additionalSnackbarErrors)

      return resolve(snackbarErrorMessages)
    })()
  })
  return { fileToReplaceForConversion, snackbarErrorMessages }
}

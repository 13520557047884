import { WorkBook, WorkSheet } from 'xlsx'

export interface IAddErrorMessagesToSheetByRow {
  cleanSheet: WorkSheet
  errorsByRowNotTitles: IErrorsByRow
  firstRowErrors: IErrorObj[]
}
export interface ICellKeysByColumn {
  [letter: string]: string[]
}

export interface IErrorsByRow {
  [number: string]: string[]
}

export interface IErrorObj {
  cellKey: string
  msg: string
}

export interface IHolidayCalendarRow {
  Comments: string
  Errors?: string
  Holiday: string
  'Holiday Date': Date
}

export interface IGetColumnLimitProps {
  expectedNumberOfColumns: number
  lastCleanSheetColumnLetter: string
}

export interface IGetFirstRowErrorProps {
  cleanSheet: WorkSheet
  expectedNumberOfColumns: number
  lastCleanSheetColumnLetter: string
  Sheets: {
    [sheet: string]: WorkSheet
  }
}

export interface IReadFileAndGetVarsParams {
  expectedNumberOfColumns: number
  file: File
}

export interface ICommonValidationVars {
  cleanSheet: WorkSheet
  snackbarErrors: string[]
  keysByColumn: ICellKeysByColumn
  lastCleanSheetColumnLetter: string
  Sheets: {
    [sheet: string]: WorkSheet
  }
  workbook: WorkBook
}

export interface IGetColumnErrorsParams{
  colIndex: number
  colType: CellTypes
  cleanSheet: WorkSheet
  keysByColumn: ICellKeysByColumn
  mustBeAscending?: boolean
  mustHaveLength?: number
  mustExist?: boolean
}

export interface IisFileValidParams {
  cleanSheet: WorkSheet
  errorsByRowNotTitles: IErrorsByRow
  fileName: string
  firstRowErrors: IErrorObj[]
  sheetName: string
  workbook: WorkBook
}

export interface IisFileValidObj {
  additionalSnackbarErrors: string[]
}

export interface IReplaceEmptyCellConfig {
  cellRange: string
  includeEmptyStrings?: boolean
  replaceWith: {
    s?: any
    t: string
    v: number
    w: string
    z: string
  }
}

export interface IValidationPromise {
  fileToReplaceForConversion?: WorkSheet
  snackbarErrorMessages: string[]
}

export interface IGetCellsFromCellRangeOptions {
  shouldExist: boolean
}

// enums
export enum CellTypes {
  Date = 'd',
  Number = 'n',
  String = 's',
  Stub = 'z'
}

export enum CellTypesNames {
  d = 'Date',
  n = 'Number',
  s = 'String',
  z = 'Stub'
}

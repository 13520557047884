import { IFileType } from 'types'

export const formatFileName = (fileType: IFileType, version: number, region: string, year: string): string => {
  switch (fileType) {
    case IFileType.Holiday:
      return `${fileType}_${region}_${year}_v${version}`
    default:
      return `${fileType}_v${version}`
  }
}

export const formatFilePath = (fileType: IFileType, fileName: string, extension: string, region: string, year: string): string => {
  switch (fileType) {
    case IFileType.Holiday:
      return `${fileType}/${region}/${year}/${fileName}.${extension}`
    default:
      return `${fileType}/${fileName}.${extension}`
  }
}

import { API } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { VersionData } from 'types'

export function useVersionData (partitionKey: string, refresh: boolean): {data: VersionData[], latest: VersionData | null, loading: boolean} {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<VersionData[]>([])
  const [latest, setLatest] = useState<VersionData | null>(null)

  useEffect(() => {
    let isMounted = true
    setLoading(true)
    API.get('datasucker', `/versions/region-calendar/${partitionKey}`, {})
      .then(res => {
        if (isMounted) {
          if (res.length !== 0) {
            setData(res)
            setLatest(res[0])
          } else {
            setLatest(null)
            setData([])
          }
        }
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
    return () => {
      isMounted = false
    }
  }, [partitionKey, refresh])

  return { data, latest, loading }
}

import * as XLSX from 'xlsx'
import SSF from 'ssf'
import moment from 'moment'

import { IFileType } from 'types'

import { convertActAdjToCSV } from './act-adj-data-csv-conversion'
import { convertBillingOrCZSKToCSV } from './billing-and-czsk-csv-conversion'

export const convertFileToCSV = async (fileType: IFileType, fileOrSheet: File | XLSX.WorkSheet, version: number, region: string, year: string): Promise<any> => {
  let json
  if (fileOrSheet instanceof File) {
    const arrayBuffer = await fileOrSheet.arrayBuffer()
    const workbook = XLSX.read(arrayBuffer, { type: 'array' })
    json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { defval: '' })
  } else {
    json = XLSX.utils.sheet_to_json(fileOrSheet, { defval: '' })
  }

  const jsonToSheetOpts: XLSX.JSON2SheetOpts = { skipHeader: true }

  if (fileType === IFileType.Holiday || fileType === IFileType.Cost) {
    json = handleDate(fileType, json)
    if (fileType === IFileType.Holiday) {
      json = modifyColumns(json, fileType, version, region, year)
      jsonToSheetOpts.header = ['Date', 'Holiday', 'Comment', 'region', 'year', 'version', 'uploadTime']
    }
  }

  if (fileType === IFileType.Actual) {
    json = handleActualsDates(json)
  }

  if (fileType === IFileType.AccountAdjustments) {
    json = convertActAdjToCSV(json)
  }

  if (fileType === IFileType.Billing) {
    json = convertBillingOrCZSKToCSV(json, 'GEN')
  }

  if (fileType === IFileType.CZSKBilling) {
    json = convertBillingOrCZSKToCSV(json, 'CZ')
  }

  json = addVersionAndUploadTime(json, version)

  const updatedWorkbook = XLSX.utils.json_to_sheet(json, jsonToSheetOpts)
  const csv = XLSX.utils.sheet_to_csv(updatedWorkbook)
  return csv
}

// Used to convert date from excel format to yyyy-mm-dd format
const handleDate = (fileType: IFileType, json: any[]): any[] => {
  json.map(row => {
    if (fileType === IFileType.Holiday) row.Date = SSF.format('YYYY-mm-dd', row.Date)
    else if (fileType === IFileType.Cost) row['Effective Date'] = SSF.format('YYYY-mm-dd', row['Effective Date'])
    return row
  })
  return json
}

const modifyColumns = (json: any[], fileType: IFileType, version: number, region?: string, year?: string): any[] => {
  json.map(row => {
    row.region = region
    row.year = year
    return row
  })
  return json
}

const addVersionAndUploadTime = (json: any[], version: number): any[] => {
  const uploadTime = moment(new Date()).format('YYYY-MM-DD HH:mmZ')
  json.map(row => {
    row.version = version
    row.uploadTime = uploadTime
    return row
  })
  return json
}

const handleActualsDates = (json: any[]): any[] => {
  const columns = ['Joining Date', 'Leaving Date', 'Entry Date', 'Week Start Date', 'Week End Date', 'Created On', 'Approval Date']
  json.map(row => {
    for (const column of columns) {
      row[column] = SSF.format('YYYY-mm-dd', row[column])// formatDateColumn(row[column])
    }
    return row
  })
  return json
}

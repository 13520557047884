import { getFirstRowErrors } from './generic-row-validation'
import { isEmpty } from 'lodash'

import { CellTypes, IErrorObj, IValidationPromise } from 'types'

import { getFileExtensionErrors } from './generic-file-validation'
import {
  getErrorsByRow, isValidOrCreateWorkbook, readFileAndGetVars
} from './xlsx-utils'
import { getColumnErrors } from './generic-column-validation'

const columnsConfigArr = [
  { cellType: CellTypes.String, mustExist: true },
  { cellType: CellTypes.String, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true }
]

export const validateExchangeRatesDataXLSXFile = async (file: File): Promise<IValidationPromise> => {
  const snackbarErrorMessages: string[] = [] // default to none
  let fileToReplaceForConversion

  await new Promise((resolve): void => {
    void (async () => {
      // validate file ext
      const fileExtensionErrors = getFileExtensionErrors(file)
      if (fileExtensionErrors !== undefined) {
        snackbarErrorMessages.push(...fileExtensionErrors)
        return resolve('')
      }

      const expectedNumberOfColumns = 4

      const { cleanSheet, keysByColumn, lastCleanSheetColumnLetter, snackbarErrors, Sheets, workbook } = await readFileAndGetVars({ expectedNumberOfColumns, file })
      if (!isEmpty(snackbarErrors)) {
        snackbarErrorMessages.push(...snackbarErrors)
        return resolve('')
      }

      // ERROR MESSAGES
      //     Title row errors
      const firstRowErrors = getFirstRowErrors({ cleanSheet, expectedNumberOfColumns, lastCleanSheetColumnLetter, Sheets })

      const columnErrorsArr: IErrorObj[][] = columnsConfigArr.map((colConfig, index) => getColumnErrors({
        colIndex: index,
        colType: colConfig.cellType,
        cleanSheet,
        keysByColumn,
        mustExist: Boolean(colConfig?.mustExist)
      }))

      const errorsByRowNotTitles = getErrorsByRow(([] as IErrorObj[]).concat(...columnErrorsArr))

      const { additionalSnackbarErrors } = isValidOrCreateWorkbook({
        cleanSheet,
        errorsByRowNotTitles,
        fileName: 'ExchangeRatesData.xlsx',
        firstRowErrors,
        sheetName: 'Exchange Rates Data',
        workbook
      })

      fileToReplaceForConversion = cleanSheet

      snackbarErrorMessages.push(...additionalSnackbarErrors)

      return resolve(snackbarErrorMessages)
    })()
  })
  return { fileToReplaceForConversion, snackbarErrorMessages }
}

import { IBillingCZSKExcelFileRow } from 'types'

type BU_CODE = 'GEN' | 'CZ'

export const convertBillingOrCZSKToCSV = (json: IBillingCZSKExcelFileRow[], buCode: BU_CODE): any => {
  const csvRows = json.map(row => ({
    ...row,
    BU_CODE: buCode
  }))

  return csvRows
}

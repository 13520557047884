import React, { useState } from 'react'
import {
  Box, Container, Grid, SelectChangeEvent, Tabs, Tab, Typography
} from '@mui/material'
import { Auth } from 'aws-amplify'

import UploadIcon from '@mui/icons-material/Upload'

import { SelectInputs } from './components'
import { fileTypeBySelectedTab } from 'common'
import CurrentData from 'components/CurrentData'
import HeaderNavigation from 'components/Header/HeaderNavigation'
import UploadButton from 'components/Upload/UploadButton'
import UploadModal from 'components/UploadModal'
import { useVersionData } from 'hooks'
import VersionTable from 'pages/Public/components/VersionTable.component'
import { IFileType } from 'types'
import { formatPartitionKey } from 'utils'

import styles from './public.styles'

export function Public (): React.ReactElement {
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [selectedRegionName, setSelectedRegionName] = useState<string>('')
  const [selectedYear, setSelectedYear] = useState<string>('')
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)
  const [refresh, triggerRefresh] = useState(false)
  const { data, latest, loading } = useVersionData(formatPartitionKey(fileTypeBySelectedTab[selectedTab], selectedRegionName, selectedYear), refresh)

  // Shouldn't this be a useEffect?
  const isUploadDisabled = (selectedTab === 0 && (selectedYear === '' || selectedRegionName === '')) || (selectedTab === 2 && selectedYear === '')

  const refreshPage = (): void => {
    triggerRefresh(!refresh)
  }

  const changeTab = (event: React.SyntheticEvent, newValue: number): void => {
    setSelectedTab(newValue)
  }

  const changeCountry = (event: SelectChangeEvent): void => {
    setSelectedRegionName(event.target.value)
  }

  const changeYear = (event: SelectChangeEvent): void => {
    setSelectedYear(event.target.value)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (isUploadDisabled) return
    const newFile = event?.target?.files?.[0]
    setSelectedFile(newFile)
    event.target.value = '' // Value should be cleared in order to select the same file after closing the modal
  }

  const handleClose = (): void => {
    setSelectedFile(undefined)
  }

  const renderSelectInputs = (fileType: IFileType): React.ReactElement => {
    switch (fileType) {
      case IFileType.Holiday:
        return (
          <SelectInputs
            selectedRegionName={selectedRegionName}
            changeCountry={changeCountry}
            selectedYear={selectedYear}
            changeYear={changeYear}
          />
        )

      default:
        return <></>
    }
  }

  return (
    <Box sx={styles.container}>
      {selectedFile !== undefined && (
        <UploadModal
          handleClose={handleClose}
          handleFileChange={handleFileChange}
          title='Upload Data File (with only 1 sheet attached)'
          file={selectedFile}
          region={selectedRegionName}
          year={selectedYear}
          selectedTab={selectedTab}
          fileType={fileTypeBySelectedTab[selectedTab]}
          refreshPage={refreshPage}
          latest={latest}
        />
      )}
      <HeaderNavigation signout={async () => await Auth.signOut()} />
      <Container data-testid='landing' disableGutters maxWidth='lg' sx={styles.table}>
        <Tabs variant='scrollable' value={selectedTab} onChange={changeTab} sx={styles.tabs} scrollButtons>
          <Tab value={0} label='HOLIDAY' sx={styles.tab} />
          {/* <Tab value={1} label='COST' sx={styles.tab} /> */}
          {/* <Tab value={2} label='ACTUALS' sx={styles.tab} /> */}
          <Tab value={3} label='BILLING' sx={styles.tab} />
          {/* <Tab value={4} label='COST PERMISSIONS' sx={styles.tab} /> */}
          <Tab value={5} label='EXCHANGE RATES' sx={styles.tab} />
          <Tab value={6} label='ONE TIME ACT. ADJ.' sx={styles.tab} />
          <Tab value={7} label='CZ SK Billing' sx={styles.tab} />
        </Tabs>
        <Box>
          <Box sx={styles.outerModalBox}>
            <Box sx={styles.innerModalBox}>
              <Box sx={styles.formControls}>
                {
                  renderSelectInputs(fileTypeBySelectedTab[selectedTab])
                }
              </Box>
              <Box sx={styles.uploadSection}>
                <Typography sx={styles.uploadDescription}>{isUploadDisabled ? 'Select Country and Year' : 'You can only upload xlsx data file type'}</Typography>
                <UploadButton isDisabled={isUploadDisabled} name='UPLOAD' onChange={handleFileChange} variant='contained' icon={<UploadIcon sx={styles.uploadIcon} />} style={styles.uploadButton} />
              </Box>
            </Box>
            <Grid container columnSpacing={1}>
              <Grid item xs={5} sx={styles.currentDataContainer}>
                <Box sx={styles.currentDataBox}>
                  <Typography>Current Data</Typography>
                  <CurrentData region={selectedRegionName} year={selectedYear} fileType={fileTypeBySelectedTab[selectedTab]} latest={latest} loading={loading} />
                </Box>
              </Grid>
              <Grid item xs={7} sx={styles.versionDataContainer}>
                <Box sx={styles.versionDataBox}>
                  <Typography>Version Data</Typography>
                </Box>
                <VersionTable data={data} loading={loading} fileType={fileTypeBySelectedTab[selectedTab]} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

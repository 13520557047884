/* istanbul ignore file */
import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#12699F'
    },
    secondary: {
      main: '#b20d30'
    }
  },
  shape: {
    borderRadius: 6
  },
  typography: {
    fontFamily: 'Inter',
    fontWeightRegular: 600,
    button: {
      textTransform: 'capitalize',
      fontWeight: 500,
      fontSize: '14px'
    },
    h3: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '22px'
    },
    h4: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '30px'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '13px 24px',
          border: 0
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#9E9E9E'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: '#12699F',
          fontSize: '14px',
          outline: 'none',
          fontWeight: 400
        }
      }
    }
  }
})

/* istanbul ignore file */
import { Storage } from 'aws-amplify'
import { IFileType } from 'types'

export const getS3FileByFileName = async (filename: string): Promise<string | undefined> => {
  try {
    const response = await Storage.get(filename)
    return response
  } catch (err) {
    console.error({ err })
  }
}

export const uploadToS3 = async (file: File | undefined, path: string, fileType: IFileType): Promise<void> => {
  try {
    await Storage.put(path, file)
  } catch (err) {
    console.log({ err })
  }
}

//  --- ERRORS ---
// common snackbar messages
export const pleaseTryAgainErrorMessage = 'Something went wrong. Please try again.'
// xlsx snackbar messages
export const delimiterErrorMessage = 'Delimitor/Separator must be ","'
export const fileExtensionErrorMessage = 'File extension must be "xlsx"'
export const fileReaderErrorMessage = 'fileReader failed to read'
export const xlsxReadErrorMessage = 'xlsx.read() failed'
export const validationFailedErrorMessage = 'Validation failed.  Open downloaded file to view errors'

// xlsx sheet error messages
//      title errors
export const sheetCountErrorMessage = 'You uploaded multiple sheets, only the first sheet was kept'
//      column errors
export const ascendingColumnErrorMessage = 'Column must be in ascending order from top to bottom'
//      cell errors
export const cellMustHaveLengthErrorMessage = (length: number): string => `Cell must have a length of ${length}`
export const cellShouldNotExistMessage = 'This cell is outside of the expected range, please delete it'
export const emptyCellErrorMessage = 'Cell cannot be empty'

// miscellaneous
export const errorColTitle = 'Errors'
export const titleErrorPrefix = 'Title Errors: '

//  --- SUCCESS ---
export const fileUploadSuccessMessage = 'Your file was uploaded successfully'

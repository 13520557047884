import { flatten, isNumber } from 'lodash'
import { CellObject } from 'xlsx'

import { ascendingColumnErrorMessage } from 'common'
import { CellTypes, IErrorObj, IGetColumnErrorsParams } from 'types'

import { cellMustHaveLength, cellMustHaveType } from './generic-cell-validation'
import { formatErrorMessage, getColLetterFromColNumber } from './xlsx-utils'
import { cellRangeValidation } from './generic-cellrange-validation'

export const getColumnErrors = ({ colIndex, colType, cleanSheet, keysByColumn, mustBeAscending = false, mustHaveLength, mustExist = true }: IGetColumnErrorsParams): IErrorObj[] => {
  const columnLetter = getColLetterFromColNumber(colIndex + 1)
  const columnKeys = keysByColumn[columnLetter]

  const columnErrors: Array<IErrorObj[] | undefined[]> = columnKeys?.map((cellKey: string, index: number): IErrorObj[] | undefined[] => {
    if (index === 0) return [undefined]// skip header cells
    const cell: CellObject = cleanSheet[cellKey]
    const cellErrors = []

    // check cell type
    if (mustExist) {
      cellErrors.push(
        cellMustHaveType(cell, cellKey, colType)
      )
    }

    // Make sure date values are ascending
    if (mustBeAscending && index !== 1 && cell?.t === colType && cell?.v !== undefined && (cell?.v < cleanSheet[columnKeys[index - 1]]?.v)) {
      const errorMessage = formatErrorMessage(ascendingColumnErrorMessage, cellKey)
      cellErrors.push(errorMessage)
    }

    // Check cell value length
    if (isNumber(mustHaveLength)) {
      if (mustExist) {
        cellErrors.push(cellMustHaveLength(String(cell?.v), cellKey, mustHaveLength))
      } else if (!mustExist && cell?.t === CellTypes.Number) {
        cellErrors.push(cellMustHaveLength(String(cell?.v), cellKey, mustHaveLength))
      }
    }

    const filteredCellErrors = cellErrors.filter(errorObj => errorObj !== undefined) as IErrorObj[]

    return filteredCellErrors.length > 0 ? filteredCellErrors : [undefined]
  })

  if (mustExist) {
    columnErrors?.push(cellRangeValidation(`${columnLetter}2:${columnKeys[columnKeys.length - 1]}`, cleanSheet))
  }

  const errors = flatten(columnErrors).filter((errorObj: IErrorObj | undefined) => errorObj !== undefined) as IErrorObj[]
  return errors
}

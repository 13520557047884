import { VersionData } from 'types'

export const mockedCountries = ['Canada', 'US', 'Romania']

export const mockedRegions = [
  { HOLIDAY_REGION: 'USA' },
  { HOLIDAY_REGION: 'Canada' },
  { HOLIDAY_REGION: 'UK' },
  { HOLIDAY_REGION: 'Latvia' },
  { HOLIDAY_REGION: 'Romania' }
]

export const mockedYears = [2016, 2017, 2018, 2019, 2020, 2021, 2022]

export const mockedRowData: VersionData = {
  country_year: '2022',
  version: 1,
  datetime: '2022-04-29',
  user: 'username',
  comment: 'comment here',
  filename: 'filename',
  versionId: 'versionId'
}

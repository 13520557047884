import { IFileType, IFileTypeBySelectedTab } from 'types'

export const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

export const fileTypeBySelectedTab: IFileTypeBySelectedTab = {
  0: IFileType.Holiday,
  1: IFileType.Cost,
  2: IFileType.Actual,
  3: IFileType.Billing,
  4: IFileType.Cost_Permissions,
  5: IFileType.ExchangeRates,
  6: IFileType.AccountAdjustments,
  7: IFileType.CZSKBilling
}

export const currentDataByFileType = (fileType: IFileType, region: string, year: string): {text: string, url: string | undefined} => {
  const data = {
    [IFileType.Holiday]: {
      text: `Holiday Calendar For ${region}, ${year}`,
      url: process.env.REACT_APP_POWERBI_HOLIDAY
    },
    [IFileType.Cost]: {
      text: 'Cost Data Dashboard',
      url: process.env.REACT_APP_POWERBI_COST
    },
    [IFileType.Actual]: {
      text: 'Actuals Data Dashboard',
      url: process.env.REACT_APP_POWERBI_ACTUALS
    },
    [IFileType.Billing]: {
      text: 'Billing Data Dashboard',
      url: process.env.REACT_APP_POWERBI_BILLING
    },
    [IFileType.Cost_Permissions]: {
      text: 'Cost Permissions Dashboard',
      url: process.env.REACT_APP_POWERBI_COST_PERMISSIONS
    },
    [IFileType.ExchangeRates]: {
      text: 'Exchange Rates Dashboard',
      url: process.env.REACT_APP_POWERBI_EXCHANGE_RATES
    },
    [IFileType.AccountAdjustments]: {
      text: 'One Time Act. Adj.',
      url: process.env.REACT_APP_POWERBI_ACT_ADJ
    },
    [IFileType.CZSKBilling]: {
      text: 'CZ SK Billing',
      url: process.env.REACT_APP_POWERBI_ACT_ADJ // todo1: replace this with the actual URL
    }

  }

  return data[fileType]
}

import { getFirstRowErrors } from './generic-row-validation'
import { isEmpty } from 'lodash'

import { CellTypes, IErrorObj, IValidationPromise } from 'types'

import { getFileExtensionErrors } from './generic-file-validation'
import {
  getErrorsByRow, getRowUniquenessErrors, isValidOrCreateWorkbook, readFileAndGetVars
} from './xlsx-utils'
import { getColumnErrors } from './generic-column-validation'

export const validateCostDataXLSXFile = async (file: File): Promise<IValidationPromise> => {
  const snackbarErrorMessages: string[] = [] // default to none
  let fileToReplaceForConversion
  await new Promise((resolve): void => {
    void (async () => {
      // validate file ext
      const fileExtensionErrors = getFileExtensionErrors(file)
      if (fileExtensionErrors !== undefined) {
        snackbarErrorMessages.push(...fileExtensionErrors)
        return resolve('')
      }

      const expectedNumberOfColumns = 4

      const { cleanSheet, keysByColumn, lastCleanSheetColumnLetter, snackbarErrors, Sheets, workbook } = await readFileAndGetVars({ expectedNumberOfColumns, file })
      if (!isEmpty(snackbarErrors)) {
        snackbarErrorMessages.push(...snackbarErrors)
        return resolve('')
      }

      // ERROR MESSAGES
      //     Title row errors
      const firstRowErrors = getFirstRowErrors({ cleanSheet, expectedNumberOfColumns, lastCleanSheetColumnLetter, Sheets })

      //    Non-title errors
      const uniqueRowErrors = getRowUniquenessErrors(cleanSheet, ['a', 'b', 'd'])
      const firstColumnErrors = getColumnErrors({
        colIndex: 0,
        colType: CellTypes.String,
        cleanSheet,
        keysByColumn
      })
      const secondColumnErrors = getColumnErrors({
        colIndex: 1,
        colType: CellTypes.String,
        cleanSheet,
        keysByColumn
      })
      const thirdColumnErrors = getColumnErrors({
        colIndex: 2,
        colType: CellTypes.Number,
        cleanSheet,
        keysByColumn
      })
      const fourthColumnErrors = getColumnErrors({
        colIndex: 3,
        colType: CellTypes.Date,
        cleanSheet,
        keysByColumn,
        mustBeAscending: true
      })
      const errorsByRowNotTitles = getErrorsByRow(([] as IErrorObj[]).concat(uniqueRowErrors, firstColumnErrors, secondColumnErrors, thirdColumnErrors, fourthColumnErrors))

      const { additionalSnackbarErrors } = isValidOrCreateWorkbook({
        cleanSheet,
        errorsByRowNotTitles,
        fileName: 'CostData.xlsx',
        firstRowErrors,
        sheetName: 'Cost Data',
        workbook
      })

      fileToReplaceForConversion = cleanSheet

      snackbarErrorMessages.push(...additionalSnackbarErrors)

      return resolve(snackbarErrorMessages)
    })()
  })
  return { fileToReplaceForConversion, snackbarErrorMessages }
}

import { IapiRoutesBySelectedTab } from 'types'

export const apiRoutesBySelectedTab: IapiRoutesBySelectedTab = {
  0: '/versions/region-calendar',
  1: '/versions/region-calendar',
  2: '/versions/region-calendar',
  3: '/versions/region-calendar',
  4: '/versions/region-calendar',
  5: '/versions/region-calendar',
  6: '/versions/region-calendar',
  7: '/versions/region-calendar',
  8: '/versions/region-calendar'
}

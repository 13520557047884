import React, { useContext, useEffect, useState } from 'react'
import { IconButton, TextField } from '@mui/material'
import { useSnackbar, VariantType } from 'notistack'

import CloseIcon from '@mui/icons-material/Close'
import Modal from 'components/Modal/Modal'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import UploadIcon from '@mui/icons-material/Upload'
import UploadButton from 'components/Upload/UploadButton'

import { postMetadata, uploadToS3 } from 'services'
import {
  convertFileToCSV,
  formatPartitionKey,
  formatFilePath,
  formatFileName,
  validateActualDataXLSXFile,
  validateBillingDataXLSXFile,
  validateCostDataXLSXFile,
  validateHolidaysXLSXFile,
  validateCostPermissionsDataXLSXFile,
  validateExchangeRatesDataXLSXFile,
  validateOneTimeActAdjDataXLSXFile
} from 'utils'
import { IFileType, IUploadModalProps } from 'types'
import { UserContext } from 'context'
import { apiRoutesBySelectedTab, fileUploadSuccessMessage, pleaseTryAgainErrorMessage } from 'common'
import styles from './index.styles'
import { WorkSheet } from 'xlsx'

const UploadModal: React.FC<IUploadModalProps> = ({ handleClose, handleFileChange, title, file, region, year, selectedTab, fileType, refreshPage, latest }) => {
  const { user } = useContext(UserContext)
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()
  const [comment, setComment] = useState<string>('')
  const [isModalDisabled, setIsModalDisabled] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)

  const versionsApiPath: string = apiRoutesBySelectedTab[selectedTab]

  let fileForCSVConversion: File | WorkSheet = file

  useEffect(() => {
    let isMounted = true
    if (isMounted && comment === '') setIsModalDisabled(true)
    else setIsModalDisabled(false)
    return () => {
      isMounted = false
    }
  }, [comment])

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setComment(event.target.value)
  }

  const showNotification = (message: string, type: VariantType): void => {
    enqueueSnackbar(message, {
      action: key =>
        <IconButton aria-label='delete' onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </IconButton>,
      variant: type,
      persist: true
    })
  }

  const isValidFile = async (): Promise<boolean> => {
    const validationFunctionsArray = [
      validateHolidaysXLSXFile,
      validateCostDataXLSXFile,
      validateActualDataXLSXFile,
      validateBillingDataXLSXFile,
      validateCostPermissionsDataXLSXFile,
      validateExchangeRatesDataXLSXFile,
      validateOneTimeActAdjDataXLSXFile,
      validateBillingDataXLSXFile
    ]

    const { snackbarErrorMessages, fileToReplaceForConversion } = await validationFunctionsArray[selectedTab](file)
    if (snackbarErrorMessages.length === 0 && fileToReplaceForConversion !== undefined) {
      fileForCSVConversion = fileToReplaceForConversion
    }

    if (snackbarErrorMessages.length > 0) {
      enqueueSnackbar(`${snackbarErrorMessages?.[0]}`, {
        action: key =>
          <IconButton aria-label='delete' onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>,
        variant: 'error',
        persist: true
      })
      return false
    } else {
      return true
    }
  }

  const handleUpload = async (): Promise<void> => {
    if (file === undefined) return
    setLoading(true)
    try {
      const isValid = await isValidFile()
      if (isValid) {
        const version = latest?.version ?? 0
        const nextVersion = version + 1
        const fileName = formatFileName(fileType, nextVersion, region, year)
        const csv = await convertFileToCSV(fileType, fileForCSVConversion, nextVersion, region, year)
        const xlsxPath = formatFilePath(fileType, fileName, 'xlsx', region, year)
        const csvFileType = fileType === IFileType.CZSKBilling ? IFileType.Billing : fileType
        const csvPath = formatFilePath(csvFileType, fileName, 'csv', region, year)
        await Promise.all([
          await postMetadata(formatPartitionKey(fileType, region, year), nextVersion, xlsxPath, region, year, user, comment, versionsApiPath),
          await uploadToS3(file, xlsxPath, fileType),
          await uploadToS3(csv, csvPath, fileType)
        ])
        handleClose()
        refreshPage()
        showNotification(fileUploadSuccessMessage, 'success')
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      showNotification(pleaseTryAgainErrorMessage, 'error')
    }
  }

  return (
    <Modal
      open
      close={handleClose}
      title={title}
      confirm='UPLOAD'
      isModalDisabled={isModalDisabled}
      cancel='CANCEL'
      submit={handleUpload}
      icon={<UploadIcon sx={styles.uploadIcon} />}
      loading={loading}
    >
      <TextField
        type='text'
        multiline
        rows={4}
        variant='filled'
        label='What are you changing?'
        onChange={handleInput}
        value={comment}
        inputProps={{ 'data-testid': 'commentTextField' }}
        sx={styles.inputChange}
      />
      <UploadButton
        variant='text'
        name={file.name ?? 'undefined'}
        onChange={handleFileChange}
        icon={<AttachFileIcon sx={styles.fileIcon} />}
        style={styles.uploadedFile}
      >
        <CloseIcon sx={styles.closeIcon} />
      </UploadButton>
    </Modal>
  )
}

export default UploadModal

export default {
  closeSection: {
    display: 'flex', justifyContent: 'flex-end'
  },
  buttonSection: {
    display: 'flex', justifyContent: 'end', marginTop: '16px'
  },
  confirmBtn: {
    backgroundColor: '#00195F',
    height: '48px',
    width: '131px'
  },
  cancelBtn: {
    color: '#9E9E9E'
  },
  loadingContent: {
    minHeight: '50vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

import { Dialog, DialogTitle, DialogContent, Box, Button, Typography, IconButton, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styles from './Modal.style'

interface ModalProps {
  open: boolean
  title?: string
  confirm?: string
  cancel?: string
  icon?: React.ReactNode
  isModalDisabled?: boolean
  close: () => void
  submit?: () => void
  children: React.ReactNode
  loading: boolean
}

const Modal: React.FC<ModalProps> = ({ open, title, confirm, isModalDisabled, cancel, close, submit, icon, children, loading }) => {
  if (loading) {
    return (
      <Dialog open={open} fullWidth>
        <DialogTitle role='heading'>
          <Typography>
            Loading... Process can take up to 1 minute depending on file size
          </Typography>
        </DialogTitle>
        <DialogContent sx={styles.loadingContent}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog open={open} fullWidth>
      <Box sx={styles.closeSection}>
        <IconButton aria-label='close' onClick={close}>
          <CloseIcon />
        </IconButton>
      </Box>
      {title !== null && title !== undefined &&
        <DialogTitle role='heading'>
          <Typography>
            {title}
          </Typography>
        </DialogTitle>}
      <DialogContent>
        {children}
        <Box sx={styles.buttonSection}>
          <Button onClick={close} variant='text' sx={styles.cancelBtn}>{cancel}</Button>
          {confirm !== null && confirm !== undefined &&
            <Button onClick={submit} disabled={isModalDisabled} variant='contained' data-testid='submitButton' sx={styles.confirmBtn}>{icon}{confirm}</Button>}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default Modal

import { IUsePublic } from 'types'

export const usePublic = (): IUsePublic => {
  const lastYear: number = new Date().getFullYear() - 1
  const yearOptions: number[] = []
  for (let i = 0; i < 6; i++) {
    yearOptions.push(lastYear + i)
  }

  return { yearOptions }
}

export default {
  container: { backgroundColor: '#f4f6fc', height: '100vh', overflow: 'auto' },
  table: { marginTop: '2rem', backgroundColor: 'white', borderRadius: '4px', border: '1px solid #E5E5E5' },
  tabs: { borderBottom: 1, borderColor: 'divider', marginBottom: '28px' },
  tab: { margin: '18px 24px 15px' },
  uploadSection: { display: 'flex', alignItems: 'center' },
  uploadDescription: { fontSize: '12px', color: '#9E9E9E', marginRight: '16px' },
  uploadButton: { backgroundColor: '#00195F', width: '130px', height: '36px', fontSize: '14px' },
  uploadIcon: { marginRight: '12px' },
  formControls: { display: 'flex', color: '#9E9E9E' },
  outerModalBox: { margin: '28px 24px' },
  innerModalBox: { display: 'flex', justifyContent: 'space-between', marginBottom: '26px' },
  currentDataBox: { display: 'flex', flexDirection: 'column', marginBottom: '24px' },
  versionDataContainer: { },
  versionDataBox: { display: 'flex', justifyContent: 'space-between', marginBottom: '24px' },
  snackbar: { display: 'block' },
  currentDataContainer: {},
  dataTable: { border: '1px solid #E5E5E5', borderRadius: '8px' }
}

export default {
  downloadIcon: {
    color: '#12699F'
  },
  table: {
    border: '1px solid #E5E5E5',
    borderRadius: '8px'
  },
  pagination: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'end'
  }
}

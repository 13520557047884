import { WorkSheet } from 'xlsx'

import { FileExtEnum, IErrorObj, IGetColumnLimitProps } from 'types'
import { fileExtensionErrorMessage, sheetCountErrorMessage } from 'common'

import { formatErrorMessage, getColNumberFromColLetter } from './xlsx-utils'

// Generate errors if number of columns is too many or too few
export const getNumberOfColumnsError = ({ expectedNumberOfColumns, lastCleanSheetColumnLetter }: IGetColumnLimitProps): IErrorObj[] => {
  const errors: IErrorObj[] = []
  const actualNumOfColumns = getColNumberFromColLetter(lastCleanSheetColumnLetter)

  if (expectedNumberOfColumns !== actualNumOfColumns) {
    const errorMessage = formatErrorMessage(`Expected number of columns was ${expectedNumberOfColumns}, but there were ${actualNumOfColumns} columns`, `${lastCleanSheetColumnLetter}1`)
    errors.push(errorMessage)
  }
  return errors
}

export const getSheetCountError = (Sheets: {[sheet: string]: WorkSheet}, lastColumnLetter: string): IErrorObj[] => {
  const errors: IErrorObj[] = []
  if (Object.keys(Sheets).length > 1) {
    const errorMessage = formatErrorMessage(sheetCountErrorMessage, `${lastColumnLetter}1`)
    errors.push(errorMessage)
  }
  return errors
}

export const getFileExtensionErrors = (file: File): string[] | undefined => {
  const fileExt: string | undefined = file.name.split('.').pop()
  let error
  if (fileExt !== FileExtEnum.XLSX) error = [fileExtensionErrorMessage]
  return error
}

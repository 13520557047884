export enum IFileType {
  Holiday = 'holiday',
  Cost = 'cost',
  Actual = 'actual',
  Billing = 'billing',
  Cost_Permissions = 'cost_perm',
  ExchangeRates = 'exchange_rates',
  AccountAdjustments = 'account_adjustments',
  CZSKBilling = 'cz_sk_billing'
}

export interface IFileTypeBySelectedTab {
  [number: number]: IFileType
}

export interface IActAdjConvertedRow {
  Account: string
  Adjustment: number
  'Adjustment note': string
  Date: string
  Location: string
  Project: string
  Units: string
};

export interface IBillingCZSKExcelFileRow {
  'Calendar Year/Month': string
  'Customer Account (EDC)': string
  'Cost Center': string
  'Cost Center - Description': string
  WBS: string
  'WBS - Description': string
  'Total FTE': number
  'Billable FTE': number
  Revenues: number
  GP: number
  version: number
  uploadTime: string
}

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:bdca5a25-a449-438b-a3ba-536498a59f11",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_biyTL93iz",
    "aws_user_pools_web_client_id": "5ugaij41v2g0a9a7dbbnlm3jmb",
    "oauth": {
        "domain": "datasucker-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dataupload.ness.com/",
        "redirectSignOut": "https://dataupload.ness.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "dataSuckerMeta-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "data-sucker-meta-prod",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "datasucker-storage134414-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://v9e2onole8.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "VersionsAPI",
            "endpoint": "https://bg8kaemus4.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "CalendarRegionsAPI",
            "endpoint": "https://4yvkigtjpe.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "datasucker",
            "endpoint": "https://2rmu3pmyxk.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;

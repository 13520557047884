import { getFirstRowErrors } from './generic-row-validation'
import { isEmpty } from 'lodash'

import { CellTypes, IErrorObj, IReplaceEmptyCellConfig, IValidationPromise } from 'types'

import { getFileExtensionErrors } from './generic-file-validation'
import {
  getErrorsByRow, getLastRowNumberFromCellRange, isValidOrCreateWorkbook, readFileAndGetVars, replaceEmptyCellsInRange
} from './xlsx-utils'
import { getColumnErrors } from './generic-column-validation'

const columnsConfigArr = [
  { cellType: CellTypes.String, mustExist: true },
  { cellType: CellTypes.String, mustExist: false },
  { cellType: CellTypes.String, mustExist: true },
  { cellType: CellTypes.String, mustExist: true },
  { cellType: CellTypes.String, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true },
  { cellType: CellTypes.Number, mustExist: true }
]

export const validateOneTimeActAdjDataXLSXFile = async (file: File): Promise<IValidationPromise> => {
  const snackbarErrorMessages: string[] = [] // default to none
  let fileToReplaceForConversion

  await new Promise((resolve): void => {
    void (async () => {
      // validate file ext
      const fileExtensionErrors = getFileExtensionErrors(file)
      if (fileExtensionErrors !== undefined) {
        snackbarErrorMessages.push(...fileExtensionErrors)
        return resolve('')
      }

      const expectedNumberOfColumns = 18

      const { cleanSheet, keysByColumn, lastCleanSheetColumnLetter, snackbarErrors, Sheets, workbook } = await readFileAndGetVars({ expectedNumberOfColumns, file })
      if (!isEmpty(snackbarErrors)) {
        snackbarErrorMessages.push(...snackbarErrors)
        return resolve('')
      }

      // ERROR MESSAGES
      //     Title row errors
      const firstRowErrors = getFirstRowErrors({ cleanSheet, expectedNumberOfColumns, lastCleanSheetColumnLetter, Sheets })

      const replaceEmptyCellConfig: IReplaceEmptyCellConfig = {
        cellRange: `G2:R${getLastRowNumberFromCellRange(cleanSheet['!ref'] ?? '')}`,
        replaceWith: {
          t: 'n',
          v: 0,
          w: '0',
          z: 'General'
        }
      }

      const sheetWithReplacedCells = replaceEmptyCellsInRange(cleanSheet, replaceEmptyCellConfig)

      const columnErrorsArr: IErrorObj[][] = columnsConfigArr.map((colConfig, index) => getColumnErrors({
        colIndex: index,
        colType: colConfig.cellType,
        cleanSheet: sheetWithReplacedCells,
        keysByColumn,
        mustExist: Boolean(colConfig?.mustExist)
      }))

      const errorsByRowNotTitles = getErrorsByRow(([] as IErrorObj[]).concat(...columnErrorsArr))

      const { additionalSnackbarErrors } = isValidOrCreateWorkbook({
        cleanSheet: sheetWithReplacedCells,
        errorsByRowNotTitles,
        fileName: 'OneTimeActAdjData.xlsx',
        firstRowErrors,
        sheetName: 'One Time Act. Adj. Data',
        workbook
      })

      fileToReplaceForConversion = sheetWithReplacedCells

      snackbarErrorMessages.push(...additionalSnackbarErrors)

      return resolve(snackbarErrorMessages)
    })()
  })
  return { fileToReplaceForConversion, snackbarErrorMessages }
}

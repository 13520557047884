import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Pagination, CircularProgress } from '@mui/material'

import DownloadIcon from '@mui/icons-material/Download'

import { IFileType, VersionTableProps } from 'types'
import { handleDownload } from 'utils'

import styles from './VersionTable.styles'

export default function VersionTable ({ data, loading, fileType }: VersionTableProps): React.ReactElement {
  const [page, setPage] = React.useState(1)
  const itemsPerPage = 10
  const numberOfPages = Math.ceil(data.length / itemsPerPage)

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number): void => {
    setPage(newPage)
  }

  if (loading) {
    return (
      <>
        <CircularProgress />
      </>
    )
  }

  return (
    <TableContainer sx={styles.table}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Version</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Changes</TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 && data.sort((a, b) => b.version - a.version).slice((page - 1) * itemsPerPage, page * itemsPerPage).map((option) =>
            <TableRow key={`${option.country_year}-${option.version}`}>
              <TableCell>
                {option.version}
              </TableCell>
              <TableCell>
                {option?.datetime?.split('T')?.[0]}
              </TableCell>
              <TableCell>
                {option.user}
              </TableCell>
              <TableCell>
                {option.comment}
              </TableCell>
              <TableCell>
                {fileType !== IFileType.Actual &&
                  <IconButton onClick={() => handleDownload(option.filename)} data-testid='downloadButton'>
                    <DownloadIcon style={styles.downloadIcon} />
                  </IconButton>}

              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        count={numberOfPages}
        page={page}
        onChange={handleChangePage}
        defaultPage={1}
        color='primary'
        sx={styles.pagination}
        data-testid='pagination'
      />

    </TableContainer>
  )
}

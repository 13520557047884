import React, { useEffect, useState } from 'react'
import {
  CircularProgress, FormControl, MenuItem, Select, SelectChangeEvent, Typography
} from '@mui/material'
import { API } from 'aws-amplify'

import { RegionProps } from 'types'
import { getRegionName } from 'utils'

import { usePublic } from '../public.hook'

import styles from './HolidayCalendarSelectInputs.styles'

interface ISelectInputsProps {
  selectedRegionName?: string
  changeCountry?: (event: SelectChangeEvent) => void
  selectedYear?: string
  changeYear?: (event: SelectChangeEvent) => void
}

export function SelectInputs ({
  selectedRegionName,
  changeCountry,
  selectedYear,
  changeYear
}: ISelectInputsProps): React.ReactElement {
  // STATE
  const [availableRegions, setAvailableRegions] = useState<RegionProps[]>([])
  const [isHolidaySelectsLoading, setIsHolidaySelectsLoading] = useState(true)

  // HOOKS
  const { yearOptions } = usePublic()

  // USEEFFECT
  useEffect(() => {
    let isMounted = true
    void (async () => {
      setIsHolidaySelectsLoading(true)
      const response = await API.get('datasucker', '/get-calendar-regions', {})
      if (isMounted) {
        setIsHolidaySelectsLoading(false)
        setAvailableRegions(response)
      }
    })()
    return () => {
      isMounted = false
    }
  }, [])

  // DROPDOWN VARS
  const regionLabel = 'Country'
  const regionLabelId = 'country-label'
  const regionTestId = 'select-country'
  const yearLabel = 'Year'
  const yearLabelId = 'year-label'
  const yearTestId = 'select-year'

  return (
    isHolidaySelectsLoading
      ? (
        <CircularProgress />
        )
      : (
        <>
          {(changeCountry !== undefined) &&
            <FormControl variant='standard' sx={styles.countryFormControl}>
              <Typography id={regionLabelId} sx={styles.inputLabel}>{regionLabel}</Typography>
              <Select label={regionLabel} labelId={regionLabelId} value={selectedRegionName} onChange={changeCountry} data-testid={regionTestId} sx={styles.selectBox}>
                {availableRegions.map(region => {
                  const regionName = getRegionName(region)
                  return (
                    <MenuItem key={regionName} value={regionName} data-testid={`${regionTestId}-${regionName}`}>{regionName}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>}
          {(changeYear !== undefined) &&
            <FormControl variant='standard' sx={styles.yearFormControl}>
              <Typography id={yearLabelId} sx={styles.inputLabel}>{yearLabel}</Typography>
              <Select label={yearLabel} labelId={yearLabelId} value={selectedYear} onChange={changeYear} data-testid={yearTestId} sx={styles.selectBox}>
                {yearOptions.map(year => <MenuItem key={year} value={year.toString()}>{year}</MenuItem>)}
              </Select>
            </FormControl>}
        </>
        )
  )
}

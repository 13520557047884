import React, { useContext } from 'react'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import { CircularProgress } from '@mui/material'

import { UserContext } from 'context'
import { Public } from './pages/Public'

const App: React.FC = () => {
  const { isUserLoading } = useContext(UserContext)

  return (
    isUserLoading === true
      ? (
        <CircularProgress data-testid='loading-progress' />
        )
      : (
        <>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Public />} />
            </Routes>
          </BrowserRouter>
        </>
        )
  )
}

export default App

export default {
  container: {
    borderBottom: '1px solid black',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#12699F',
    height: '56px'
  },
  logoContainer: {
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    fontSize: '50px'
  },
  name: {
    fontSize: '20px'
  },
  logo: {
    margin: '16px',
    width: '40px',
    height: '40px'
  },
  box: {
    m: 3,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white'
  },
  iconButton: {
    m1: 1
  },
  avatar: {
    width: 32,
    height: 32,
    backgroundColor: '#2196F3'
  },
  menu: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  },
  profile: { minWidth: 100, fontWeight: '400', color: 'white', marginRight: 1 }

}

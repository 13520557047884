import { ICurrentDataProps } from 'types'
import { useEffect, useState } from 'react'
import { Button, Card, CardContent, Box, CircularProgress } from '@mui/material'
import styles from './index.styles'
import { currentDataByFileType } from 'common'

const CurrentData: React.FC<ICurrentDataProps> = ({ fileType, region, year, latest, loading }) => {
  const [url, setUrl] = useState<string | undefined>('')
  const [text, setText] = useState<string>('')

  useEffect(() => {
    const { text, url } = currentDataByFileType(fileType, region, year)
    setText(text)
    setUrl(url)
  }, [region, year, fileType])

  if (loading) {
    return (
      <>
        <CircularProgress />
      </>
    )
  }

  if (latest === null) {
    return (
      <>
        No data
      </>
    )
  }

  return (
    <Card elevation={0} sx={styles.card}>
      <CardContent sx={styles.text}>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            <Box sx={styles.heading}>
              Last Updated
            </Box>
            <Box>
              {latest.datetime.split('T')[0]}
            </Box>
          </Box>
          <Box>
            <Box sx={styles.heading}>
              Version
            </Box>
            <Box>
              {latest.version}
            </Box>
          </Box>
        </Box>
        <Button
          sx={styles.button}
          variant='text'
          color='primary'
          fullWidth
          onClick={() => window.open(url, '_blank')}
        >
          View {text}
        </Button>
      </CardContent>
    </Card>
  )
}

export default CurrentData
